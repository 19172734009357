<template>
  <!--====== sp-vpn PRICING PART START ======-->

  <section class="sp-vpn-pricing-area pt-90 pb-90" id="plans">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sp-vpn-section-title text-center">
            <h3 class="sp-vpn-title">{{ title }}</h3>
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
      <div class="tabed-content">
        <div id="month" :style="[switchPlan?{'display':'block'}:{'display':'none'}]">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 wow animated fadeInLeft">
              <div class="pricing-one__single">
                <div class="pricig-heading">
                  <h6>Месяц</h6>
                  <div class="price-range"><span>500</span><sup>Р</sup></div>
                  <p>Получи 1 день за 1 рубль</p>
                </div>
                <div class="pricig-body">
                  <ul>
                    <li>Попробуй нашу скорость!</li>
                    <li>🚀 Скорость до 10 GB/s</li>
                    <li>⚙️ Доступен Wireguard и Outline</li>
                    <li>🔒 Надежные и безопасные сервера</li>
                    <li>🛜 До 3х устройств одновременно</li>
                    <li>🕵️ Не собираем и не продаем ваши данные</li>
                    <li>📱 Для Windows, Android, iPhone, MacOS, Linux</li>
                  </ul>
                  <div class="pricing-btn mt-35">
                    <a class="main-btn" href="https://t.me/sp_vpn_bot?start=pay-month_from-siteTariffBtn"
                       target="_blank">Выбрать</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow animated fadeInUp">
              <div class="pricing-one__single">
                <div class="pricig-heading">
                  <h6>Год</h6>
                  <div class="price-range"><span>3600</span><sup>Р</sup>
                    <div style="float: right; padding-top: 1.3rem; font-size: 80%">300<sup
                        style="font-size: 60%">Р</sup>/мес
                    </div>
                  </div>
                  <p>Получи 1 день за 1 рубль</p>
                </div>
                <div class="pricig-body">
                  <ul>
                    <li>💥40% скидка!</li>
                    <li class="additional">🚀 Скорость до 10 GB/s</li>
                    <li class="additional">⚙️ Доступен Wireguard и Outline</li>
                    <li class="additional">🔒 Надежные и безопасные сервера</li>
                    <li class="additional">🛜 До 3х устройств одновременно</li>
                    <li class="additional">🕵️ Не собираем и не продаем ваши данные</li>
                    <li class="additional">📱 Для Windows, Android, iPhone, MacOS, Linux</li>
                  </ul>
                  <div class="pricing-btn mt-35">
                    <a class="main-btn" href="https://t.me/sp_vpn_bot?start=pay-half0year_from-siteTariffBtn"
                       target="_blank">Выбрать</a>
                  </div>
                </div>
                <div class="pricing-rebon">
                  <span>-40%</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow animated fadeInRight">
              <div class="pricing-one__single center">
                <div class="pricig-heading">
                  <h6>3 Года</h6>
                  <div class="price-range"><span>9000</span><sup>Р</sup>
                    <div style="float: right; padding-top: 1.3rem; font-size: 80%">250<sup
                        style="font-size: 60%">Р</sup>/мес
                    </div>
                  </div>
                </div>
                <div class="pricig-body">
                  <ul>
                    <li>💥50% скидка!</li>
                    <li class="additional">🚀 Скорость до 10 GB/s</li>
                    <li class="additional">⚙️ Доступен Wireguard и Outline</li>
                    <li class="additional">🔒 Надежные и безопасные сервера</li>
                    <li class="additional">🛜 До 3х устройств одновременно</li>
                    <li class="additional">🕵️ Не собираем и не продаем ваши данные</li>
                    <li class="additional">📱 Для Windows, Android, iPhone, MacOS, Linux</li>
                  </ul>
                  <div class="pricing-btn mt-35">
                    <a class="main-btn" href="https://t.me/sp_vpn_bot?start=pay-year_from-siteTariffBtn"
                       target="_blank">Выбрать</a>
                  </div>
                </div>
                <div class="pricing-rebon">
                  <span>-50%</span>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /#month -->
      </div>
    </div>
    <br>
    <br>
    <br>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sp-vpn-section-title text-center">
            <h3 class="sp-vpn-title">Премиум планы</h3>
          </div>
        </div>
      </div>
      <div class="tabed-content">
        <div id="month" :style="[switchPlan?{'display':'block'}:{'display':'none'}]">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 wow animated fadeInLeft">
              <div class="pricing-one__single">
                <div class="pricig-heading">
                  <h6>Месяц</h6>
                  <div class="price-range"><span>700</span><sup>Р</sup></div>
                  <p>Получи 1 день за 1 рубль</p>
                </div>
                <div class="pricig-body">
                  <ul>
                    <li>Попробуй нашу скорость!</li>
                    <li>🚀 Скорость до 10 GB/s</li>
                    <li>⚙️ Доступен Wireguard и Outline</li>
                    <li>🔒 Надежные и безопасные сервера</li>
                    <li>🛜 До 10 устройств одновременно</li>
                    <li>⭐️ Премиум сервера</li>
                    <li>🕵️ Не собираем и не продаем ваши данные</li>
                    <li>📱 Для Windows, Android, iPhone, MacOS, Linux</li>
                  </ul>
                  <div class="pricing-btn mt-35">
                    <a class="main-btn" href="https://t.me/sp_vpn_bot?start=pay-month_from-siteTariffBtn"
                       target="_blank">Выбрать</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow animated fadeInUp">
              <div class="pricing-one__single">
                <div class="pricig-heading">
                  <h6>Год</h6>
                  <div class="price-range"><span>5000</span><sup>Р</sup>
                    <div style="float: right; padding-top: 1.3rem; font-size: 80%">416<sup
                        style="font-size: 60%">Р</sup>/мес
                    </div>
                  </div>
                  <p>Получи 1 день за 1 рубль</p>
                </div>
                <div class="pricig-body">
                  <ul>
                    <li>💥40% скидка!</li>
                    <li class="additional">🚀 Скорость до 10 GB/s</li>
                    <li class="additional">⚙️ Доступен Wireguard и Outline</li>
                    <li class="additional">🔒 Надежные и безопасные сервера</li>
                    <li class="additional">🛜 До 10 устройств одновременно</li>
                    <li class="additional">⭐️ Премиум сервера</li>
                    <li class="additional">🕵️ Не собираем и не продаем ваши данные</li>
                    <li class="additional">📱 Для Windows, Android, iPhone, MacOS, Linux</li>
                  </ul>
                  <div class="pricing-btn mt-35">
                    <a class="main-btn" href="https://t.me/sp_vpn_bot?start=pay-half0year_from-siteTariffBtn"
                       target="_blank">Выбрать</a>
                  </div>
                </div>
                <div class="pricing-rebon">
                  <span>-40%</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow animated fadeInRight">
              <div class="pricing-one__single center">
                <div class="pricig-heading">
                  <h6>3 Года</h6>
                  <div class="price-range"><span>12600</span><sup>Р</sup>
                    <div style="float: right; padding-top: 1.3rem; font-size: 80%">350<sup
                        style="font-size: 60%">Р</sup>/мес
                    </div>
                  </div>
                </div>
                <div class="pricig-body">
                  <ul>
                    <li>💥50% скидка!</li>
                    <li class="additional">🚀 Скорость до 10 GB/s</li>
                    <li class="additional">⚙️ Доступен Wireguard и Outline</li>
                    <li class="additional">🔒 Надежные и безопасные сервера</li>
                    <li class="additional">🛜 До 10 устройств одновременно</li>
                    <li class="additional">⭐️ Премиум сервера</li>
                    <li class="additional">🕵️ Не собираем и не продаем ваши данные</li>
                    <li class="additional">📱 Для Windows, Android, iPhone, MacOS, Linux</li>
                  </ul>
                  <div class="pricing-btn mt-35">
                    <a class="main-btn" href="https://t.me/sp_vpn_bot?start=pay-year_from-siteTariffBtn"
                       target="_blank">Выбрать</a>
                  </div>
                </div>
                <div class="pricing-rebon">
                  <span>-50%</span>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /#month -->
      </div>
    </div>
  </section>

  <!--====== sp-vpn PRICING PART ENDS ======-->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    }
  },
  data() {
    return {
      switchPlan: true
    }
  },
  methods: {
    change_plan() {
      this.switchPlan = !this.switchPlan
    },
  }

}
</script>

<style>

</style>
